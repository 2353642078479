import {api} from '@/api';
import {defineStore} from 'pinia';
import {parseDate} from '@jetCommon/helpers/date.js';

export const useSpaStore = defineStore('spa', () => {
    const company = ref(null);
    const companyId = ref(null);
    const companySettings = ref(null);
    const companyAddOns = ref(null);
    const employee = ref(null);
    const employeeId = ref(null);
    const manager = ref(null);
    const managerId = ref(null);
    const contractor = ref(null);

    const isEmployeeTerminated = computed(() =>
        employee.value?.in_day_contract_end_date
            ? parseDate(employee.value?.in_day_contract_end_date) < new Date()
            : false,
    );

    const isContractor = computed(() => employee.value?.is_contractor);

    // TODO: add watch on companyId, employeeId and managerId that calls fetchCompany, fetchEmployee and fetchManager

    async function fetchCompany(expandSettings = false, expandSalaryPaymentSettings = false) {
        company.value =
            (
                await api.companies
                    .retrieve(companyId.value, {
                        ...(expandSettings && {expand_settings: true}),
                        ...(expandSalaryPaymentSettings && {expand_salary_payment_settings: true}),
                    })
                    .catch(api.end)
            ).data ?? null;
        if (company.value && expandSettings) {
            companySettings.value = company.value.settings;
        }
        if (company.value) {
            companyAddOns.value =
                (await api.companies.retrieveCompanyAddOns(companyId.value).catch(api.end))?.data ?? null;
        }
    }

    async function fetchEmployee() {
        employee.value = (await api.employees.retrieve(employeeId.value).catch(api.end)).data ?? null;
    }

    async function fetchManager() {
        manager.value = managerId.value
            ? ((await api.managers.retrieve(managerId.value).catch(api.end)).data ?? null)
            : null;
    }

    async function fetchContractor() {
        contractor.value = (await api.contractors.retrieve(employee.value?.contractor).catch(api.end)).data ?? null;
    }

    async function onJetRouteEntityValueChange(jetRouteEntityValue) {
        employeeId.value = jetRouteEntityValue;
        await fetchEmployee();
        managerId.value = employee.value.manager;
        companyId.value = employee.value.company;
        await fetchManager();
        await fetchCompany(true);
        if (employee.value?.contractor) {
            await fetchContractor();
        }
    }

    function setIBAN(iban, bic) {
        if (!employee.value) {
            return;
        }
        employee.value.iban = iban;
        employee.value.bic = bic;
    }

    return {
        company,
        companySettings,
        companyId,
        employee,
        employeeId,
        manager,
        managerId,
        isEmployeeTerminated,
        isContractor,
        companyAddOns,
        fetchCompany,
        fetchEmployee,
        fetchManager,
        onJetRouteEntityValueChange,
        contractor,
        setIBAN,
    };
});
