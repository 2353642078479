import SafetyAndHealthApiCommon from '@jetCommon/api/safety-and-health.js';

export default class SafetyAndHealthApi extends SafetyAndHealthApiCommon {
    uploadCertificate(id, data) {
        return this.apiPost(`${id}/upload_certificate/`, data);
    }

    goToNextContent(id) {
        return this.apiPost(`${id}/go_to_next_content/`);
    }

    requestForInvitation(id) {
        return this.apiPost(`${id}/request_for_invitation/`);
    }
}
